import { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import auth from "../services/token"; // Importation des fonctions pour gérer le token
import { api } from "../services/baseUrl";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const token = auth.getToken();

  useEffect(() => {
    const fetchUser = async () => {
      if (!auth.getExpiryTime()) {
        setError("Session expirée. Veuillez vous reconnecter.");
        localStorage.removeItem("tokenlpr");
        setUser(null);
        return;
      }

      try {
        const response = await api.get("/currentuser", {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const userData = response.data.data.user;
        setUser(userData);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Session expirée. Veuillez vous reconnecter.");
          localStorage.removeItem("tokenlpr");
          setUser(null);
        } else {
          setError("Erreur lors de la récupération des données utilisateur.");
        }
      }
    };

    fetchUser();
    window.addEventListener("focus", fetchUser);

    return () => {
      window.removeEventListener("focus", fetchUser);
    };
  }, [token]);

  const isAuthenticated = !!user;

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{ user, login, logout, isAuthenticated, error }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext, UserProvider };