import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FaGithub } from "react-icons/fa";
import { AiOutlineCaretRight } from "react-icons/ai";
import { api } from "../../services/baseUrl";
import token from "../../services/token";

const ProjectDetails = () => {
  const { id_project } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch project details
  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await api.get(`/projects/${id_project}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProject(response.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des détails du projet:",
          error
        );
        setLoading(false);
      }
    };

    fetchProject();
  }, [id_project, token]);

  // Gérer le chargement des données
  if (loading)
    return (
      <div className="text-center text-xl font-light text-[#f5f5f5]">
        Chargement...
      </div>
    );

  // Si le projet est introuvable
  if (!project)
    return (
      <div className="text-center text-xl font-light text-[#f5f5f5]">
        Aucun projet trouvé.
      </div>
    );

  return (
    <div className="w-full min-h-screen bg-[#44427d] py-16 text-white">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left">
        {/* Bouton retour */}
        <Link
          to="/"
          className="flex items-center justify-start my-8 text-[#e1927f] font-bold capitalize cursor-pointer"
        >
          <AiOutlineCaretRight size={25} />
          retour
        </Link>

        {/* Nom du projet */}
        <h1 className="text-4xl md:text-5xl font-bold text-[#e1927f] tracking-wider capitalize mb-8">
          {project.name_project}
        </h1>

        {/* Image du projet */}
        <div className="relative w-full h-auto mx-auto my-8">
          <img
            src={`https://api.test.leproutludovic.fr/storage/uploads/projects/${project.image_project}`}
            alt={project.name_project}
            className="w-full h-auto object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Description */}
        <h2 className="text-2xl font-semibold text-[#e1927f] my-4">
          Description
        </h2>
        <p className="text-[#f5f5f5] text-lg">{project.description_project}</p>

        {/* Stacks Utilisés */}
        <h3 className="text-2xl font-semibold text-[#e1927f] my-4">
          Stacks Utilisés
        </h3>
        <div className="flex flex-wrap justify-center gap-2 mb-8">
          {project.stacks && project.stacks.length > 0 ? (
            project.stacks.map((stack) => (
              <span
                key={stack.id_stack}
                className="bg-white text-[#44427d] rounded-full px-4 py-2 text-sm mr-2 mb-2"
              >
                {stack.name_stack}
              </span>
            ))
          ) : (
            <p className="text-[#f5f5f5]">Aucun stack trouvé pour ce projet.</p>
          )}
        </div>

        {/* Actions (Demo et Github) */}
        <div className="flex items-center justify-center gap-10 mt-8">
          {/* Lien vers la démo */}
          {project.url_demo && (
            <a
              href={project.url_demo}
              target="_blank"
              rel="noopener noreferrer"
              className="group flex items-center justify-center bg-[#e1927f] text-white px-6 py-3 font-bold uppercase rounded-md cursor-pointer hover:bg-[#dd7055]"
            >
              Voir la démo
              <span className="ml-2 duration-200 ease-in group-hover:ml-4">
                <AiOutlineCaretRight size={18} />
              </span>
            </a>
          )}

          {/* Lien vers Github */}
          {project.github_url && (
            <a
              href={project.github_url}
              target="_blank"
              rel="noopener noreferrer"
              className="group flex items-center justify-center bg-gray-700 text-white px-6 py-3 font-bold uppercase rounded-md cursor-pointer hover:bg-gray-800"
            >
              Voir sur Github
              <span className="ml-2 duration-200 ease-in group-hover:ml-4">
                <FaGithub size={18} />
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
