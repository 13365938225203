import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-full min-h-screen bg-[#44427d] text-white flex flex-col items-center justify-center">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <p className="text-2xl mb-8">Page non trouvée</p>
      <Link to="/">
        <a className="px-6 py-3 bg-[#e1927f] text-white rounded-lg text-lg font-semibold hover:bg-[#d97b6b] transition duration-300">
          Retour à la page d'accueil
        </a>
      </Link>
    </div>
  );
};

export default NotFound;
