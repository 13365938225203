import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { api } from "../../services/baseUrl";
const TertiaryContainer = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Récupérer les projets depuis l'API
    api
      .get("/projects")
      .then((response) => {
        // Filtrer les 4 derniers projets
        const lastFourProjects = response.data.data.slice(0, 4);
        setProjects(lastFourProjects);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des projets:", error);
      });
  }, []);

  return (
    <div id="portfolio" className="w-full bg-[#e1927f] py-16 text-white">
      <div className="max-w-screen-xl mx-auto px-8 text-center md:text-left">
        <h2 className="text-5xl md:text-7xl tracking-wider uppercase text-[#44427d] font-bold mb-6">
          Projets réalisés
        </h2>
        <p className="py-4 max-w-lg mx-auto text-lg font-light text-[#f5f5f5]">
          Voici quelques exemples de projets que j'ai réalisés.
        </p>

        {/* Lien vers la liste complète des projets */}
        <div className="text-center mb-8">
          <NavLink
            to="/allprojects"
            className="text-[#44427d] font-semibold text-xl hover:text-[#44427d] hover:underline transition-all duration-300"
          >
            Voir tous les projets
          </NavLink>
        </div>

        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map(
            ({
              id_project,
              name_project,
              image_project,
              description_project,
            }) => (
              <NavLink
                key={id_project}
                to={`/portfolio/${id_project}`}
                className="block overflow-hidden bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div
                  className="relative overflow-hidden bg-gray-300"
                  style={{ height: "295px" }}
                >
                  <img
                    src={`https://api.test.leproutludovic.fr/storage/uploads/projects/${image_project}`}
                    alt={name_project}
                    className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-105"
                  />
                </div>
                <div className="p-4">
                  <h2 className="text-xl font-semibold text-[#44427d] transition-colors duration-300 hover:text-[#e1927f]">
                    {name_project}
                  </h2>
                  <p className="mt-2 text-sm text-[#44427d] line-clamp-2">
                    {description_project}
                  </p>
                </div>
              </NavLink>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TertiaryContainer;
