import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../hooks/UserContext";
import { api } from "../../services/baseUrl";
import { useNavigate } from "react-router-dom";

const AdminUsers = () => {
  const { token } = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [nameUser, setNameUser] = useState("");
  const [email, setEmail] = useState("");
  const [descriptionUser, setDescriptionUser] = useState("");
  const [pictureUser, setPictureUser] = useState(null); // Pour la photo utilisateur
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/homeuser", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fetchedUser = response.data.data;
        setUser(fetchedUser);
        setNameUser(fetchedUser.name_user);
        setEmail(fetchedUser.email);
        setDescriptionUser(fetchedUser.description_user);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur :",
          error
        );
      }
    };
    fetchUser();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    const formData = new FormData();
    formData.append("name_user", nameUser);
    formData.append("email", email);
    formData.append("description_user", descriptionUser);
    if (pictureUser) {
      formData.append("picture_user", pictureUser);
    }
    formData.append("_method", "PUT");

    try {
      await api.post(`/user/1`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccessMessage("Utilisateur mis à jour avec succès !");
    } catch (error) {
      setErrorMessage("Erreur lors de la mise à jour de l'utilisateur.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full min-h-screen mt-10 bg-[#44427d] text-white py-16">
      <div className="max-w-screen-xl mx-auto px-8">
        <h2 className="text-3xl md:text-5xl tracking-wider uppercase text-[#e1927f] font-bold mb-8">
          Modifier l'Utilisateur
        </h2>
        <button
          onClick={() => navigate("/admin")}
          className="mb-8 p-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
        >
          Retour à l'administration
        </button>
        {user ? (
          <form onSubmit={handleSubmit} className="space-y-6 mb-12">
            <div>
              <label htmlFor="name" className="block text-lg mb-2">
                Nom de l'utilisateur
              </label>
              <input
                id="name"
                type="text"
                value={nameUser}
                onChange={(e) => setNameUser(e.target.value)}
                className="w-full p-2 rounded bg-white text-black"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-lg mb-2">
                Email de l'utilisateur
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 rounded bg-white text-black"
                required
              />
            </div>
            <div>
              <label htmlFor="description" className="block text-lg mb-2">
                Description de l'utilisateur
              </label>
              <textarea
                id="description"
                value={descriptionUser}
                onChange={(e) => setDescriptionUser(e.target.value)}
                className="w-full p-2 rounded bg-white text-black"
              />
            </div>
            <div>
              <label htmlFor="picture" className="block text-lg mb-2">
                Photo de l'utilisateur
              </label>
              <input
                id="picture"
                type="file"
                accept="image/*"
                onChange={(e) => setPictureUser(e.target.files[0])}
                className="w-full p-2 rounded bg-white text-black"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 rounded bg-[#e1927f] text-white font-semibold hover:bg-[#d97b6b] transition duration-300"
            >
              {loading ? "Mise à jour en cours..." : "Mettre à jour"}
            </button>
            {successMessage && (
              <p className="text-green-500 mt-4">{successMessage}</p>
            )}
            {errorMessage && (
              <p className="text-red-500 mt-4">{errorMessage}</p>
            )}
          </form>
        ) : (
          <p className="text-gray-400">Chargement des informations...</p>
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
