import React, { useState, useEffect } from "react";
import backgroundImage from "../../assets/images/backgrounds/22bg.jpg";
import cloudImage from "../../assets/images/others/clouds.png";

const FirstContainer = () => {
  const [offset1, setOffset1] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(true);

  const phrases = [
    "Concepteur Développeur d'Applications",
    "Créateur de Solutions Digitales",
    "Développeur Fullstack Passionné",
    "Construire des Applications Innovantes",
    "Transformateur d'idées en Code",
    "Curieux, motivé et toujours prêt à apprendre",
    "Développeur junior, toujours à la recherche de nouveaux défis",
    "Codeur passionné avec un œil sur l'innovation",
  ];

  useEffect(() => {
    const animateClouds = () => {
      setOffset1((prev) => (prev <= -200 ? 0 : prev - 0.05));
    };

    const interval = setInterval(animateClouds, 120);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setIsTextVisible(false); // Hide the text before changing
      setTimeout(() => {
        setTextIndex((prev) => (prev + 1) % phrases.length); // Change phrase after fade out
        setIsTextVisible(true); // Show the new phrase with fade-in
      }, 500); // Wait for the fade-out to complete before changing text
    }, 5000); // Change phrase every 3 seconds
    return () => clearInterval(textInterval);
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      {/* Image de fond */}
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`, // Pas besoin de .src
          backgroundAttachment: "fixed",
          backgroundSize: "150% 100%",
        }}
      ></div>

      {/* Nuages (première couche) */}
      {[0, 1].map((duplication) => (
        <div
          key={`cloud1-${duplication}`}
          className={`absolute w-[300%] h-full bg-repeat-x bg-cover`}
          style={{
            backgroundImage: `url(${cloudImage})`,
            transform: `translateX(${offset1}%)`,
            height: "85%",
            bottom: 0,
            left: `${duplication * 100}%`,
            transition: "transform 0.1s linear",
          }}
        ></div>
      ))}

      {/* Texte */}
      <div className="relative flex flex-col items-center justify-center h-full text-center text-white">
        <h1 className="text-7xl font-bold">LEPROUT LUDOVIC</h1>
        <p
          className={`mt-4 text-2xl transition-opacity duration-500 ${
            isTextVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          {phrases[textIndex]}
        </p>
      </div>
    </div>
  );
};

export default FirstContainer;
