import React from "react";
import { NavLink } from "react-router-dom";

const AdminPanel = () => {
  return (
    <div className="w-full min-h-screen bg-[#44427d] text-white py-16 flex items-center justify-center">
      <div className="max-w-screen-xl mx-auto px-8">
        <h2 className="text-3xl md:text-5xl tracking-wider uppercase text-[#e1927f] font-bold mb-8">
          Admin Panel
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <NavLink
            to="/admin/projects"
            className="p-4 bg-[#e1927f] text-white rounded-lg text-center transition duration-300 hover:bg-[#d97b6b] flex items-center justify-center"
          >
            Gérer les Projets
          </NavLink>
          <NavLink
            to="/admin/stacks"
            className="p-4 bg-[#e1927f] text-white rounded-lg text-center transition duration-300 hover:bg-[#d97b6b] flex items-center justify-center"
          >
            Gérer les Stacks
          </NavLink>
          <NavLink
            to="/admin/users"
            className="p-4 bg-[#e1927f] text-white rounded-lg text-center transition duration-300 hover:bg-[#d97b6b] flex items-center justify-center"
          >
            Gérer les Utilisateurs
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
